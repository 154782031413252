const baseFormRules = {
    password: [
        {required: true, message: '密码信息不能为空', trigger: 'blur'},
        {max: 30, message: '至多输入30个字符', trigger: 'blur'}
    ],
    email: [
        {required: true, message: '邮箱信息不能为空', trigger: 'blur'},
        {type: 'email', message: '邮箱格式不正确', trigger: 'blur'},
        {max: 50, message: '至多输入50个字符', trigger: 'blur'}
    ],
    company: [
        {required: true, message: '公司不能为空', trigger: 'blur'}
    ],
    name: [
        {required: true, message: '名称不能为空', trigger: 'blur'}
    ],
    title: [
        {required: true, message: '标题不能为空', trigger: 'blur'}
    ],
    start_time: [
        {required: true, message: '开始时间不能为空', trigger: 'blur'}
    ],
    end_time: [
        {required: true, message: '结束时间不能为空', trigger: 'blur'}
    ],
}
export default baseFormRules
