<template>
  <el-row class="root" type="flex" justify="center" align="top">
    <el-col :xs="22" :sm="14" :md="10" :lg="6" :xl="6" class="login-form-box">
      <el-card shadow="always">
        <h1 id="login-title">服务中心</h1>
        <el-form :model="loginForm" :rules="loginFormRules" label-position="left" ref="loginFormRef" size="large">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="loginForm.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="loginForm.password" show-password placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button id="login-btn" :loading="loading" type="primary" @click="loginSubmit">登录</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import baseFormRules from "@/utils/baseFormRules";
import localStorage from "@/utils/localStorage";

export default {
  name: "Login",
  metaInfo: {
    title: "服务中心 - 登录",
  },
  data() {
    return {
      // 登录表单
      loginForm: {
        email: localStorage.getItem("login_email", ""),
        password: "",
      },
      // 登录表单验证规则
      loginFormRules: {
        email: baseFormRules.email,
        password: baseFormRules.password,
      },
      // 登录按钮loading
      loading: false,
    };
  },
  methods: {
    // 登录获取用户token
    loginSubmit() {
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          this.loading = true;

          this.$api.auth.login(this.loginForm).then((res) => {
            this.loading = false;

            let { code, msg, data } = res;
            if (code !== 0) {
              this.$message.error(msg);
              return false;
            }
            this.$message.success("用户认证成功！");

            localStorage.setItem("token", data.token);
            localStorage.setItem("user", data);
            localStorage.setItem("login_email", this.loginForm.email);

            this.$router.replace({ path: "/" });
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.root {
  height: 100%;
  background-image: url("../assets/images/regBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  .login-form-box {
    margin-top: 15vh;
  }
}

.el-card {
  background-size: cover;
  background-image: url("../assets/images/regBg.jpg");
}

#login-title {
  text-align: center;
  color: #606266;
}

#login-btn {
  width: 100%;
  margin-top: 20px;
}
</style>
